import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"

const Imprint = () => (
  <Layout>
    <SEO title="Impressum" />
      <Typography variant="h5" component="h1" gutterBottom>
        Impressum
      </Typography>
      <Typography variant="body1" gutterBottom>
        verantwortlich im Sinne des §6 MDStV & 6 TDG:<br /><br />
        <strong>Maik Hörz MH3 GmbH</strong><br />
        Niederlassung: Lichtensteinstrasse 45<br />
        72124 Pliezhausen<br /><br />
        Telefon: ++49 -(0) 7127-5607-233<br />
        Fax: ++49 - (0) 7127-5607-100<br />
        <a href="http://www.hotelentertainment.de" target="_blank" rel="noopener noreferrer">hotelentertainment.de</a><br />
        <a href="mailto:info@hotelentertainment.de">info@hotelentertainment.de</a><br />
        <strong>Handelsregister:</strong><br />
        Amtsgericht Stuttgart<br />
        HRB Nr.: 734185<br />
        USt-Nr.: 78094/62707<br />
        USt-idNr: DE 272020767<br /><br />
        Design und Programmierung:<br />
        Sacha Hertel - <a href="http://www.sashion.de" target="_blank" rel="noopener noreferrer">sashion.de</a><br />
      </Typography>
  </Layout>
)

export default Imprint
